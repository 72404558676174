import {Box, Button, TextField, Typography, Theme, useMediaQuery} from "@mui/material";
import {useState} from "react";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
  button: {
    borderRadius: "0 48px 48px 0", 
    background: "var(--yellow)", 
    color: "var(--black)",
    paddingLeft: "0",
    paddingRight: "0",
    minWidth: "100px"
  }
})

const INIT = "INIT";
const SUBMITTING = "SUBMITTING";
const ERROR = "ERROR";
const SUCCESS = "SUCCESS";
const formStates = [INIT, SUBMITTING, ERROR, SUCCESS] as const;
const formStyles = {
  "id": "cm5r5qnxy03fzzew0q5ohwkac",
  "name": "Default",
  "formStyle": "inline",
  "placeholderText": "Enter your email address...",
  "formFont": "Inter",
  "formFontColor": "#1c1a1f",
  "formFontSizePx": 14,
  "buttonText": "Subscribe",
  "buttonFont": "Inter",
  "buttonFontColor": "#1c1a1f",
  "buttonColor": "#ffe5a6",
  "buttonFontSizePx": 14,
  "successMessage": "Thank you for subscribing!",
  "successFont": "Inter",
  "successFontColor": "#1c1a1f",
  "successFontSizePx": 14,
  "userGroup": "Embedded"
}
const domain = "app.loops.so"

export function Subscribe() {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const classes = useStyles()

  const [email, setEmail] = useState("");
  const [formState, setFormState] = useState<typeof formStates[number]>(INIT);
  const [errorMessage, setErrorMessage] = useState("");
  const [fields, setFields] = useState({})

  const resetForm = () => {
    setEmail("");
    setFormState(INIT);
    setErrorMessage("");
  };

  /**
   * Rate limit the number of submissions allowed
   * @returns {boolean} true if the form has been successfully submitted in the past minute
   */
  const hasRecentSubmission = () => {
    const time = new Date();
    const timestamp = time.valueOf();
    const previousTimestamp = localStorage.getItem("loops-form-timestamp");

    // Indicate if the last sign up was less than a minute ago
    if (
      previousTimestamp &&
      Number(previousTimestamp) + 60 * 1000 > timestamp
    ) {
      setFormState(ERROR);
      setErrorMessage("Too many signups, please try again in a little while");
      return true;
    }

    localStorage.setItem("loops-form-timestamp", timestamp.toString());
    return false;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    // Prevent the default form submission
    event.preventDefault();

    // boundary conditions for submission
    if (formState !== INIT) return;
    if (!isValidEmail(email)) {
      setFormState(ERROR);
      setErrorMessage("Please enter a valid email");
      return;
    }
    if (hasRecentSubmission()) return;
    setFormState(SUBMITTING);

    // build additional fields
    const additionalFields = Object.entries(fields).reduce(
      (acc, [key, val]) => {
        if (typeof val === "string" || typeof val === "number" || typeof val === "boolean") {
          return acc + "&" + key + "=" + encodeURIComponent(val);
        }
        return acc;
      },
      ""
    );
    
    // build body
    const formBody = `userGroup=${encodeURIComponent(
      formStyles.userGroup
    )}&email=${encodeURIComponent(email)}&mailingLists=`;

    // API request to add user to newsletter
    fetch(`https://${domain}/api/newsletter-form/${formStyles.id}`, {
      method: "POST",
      body: formBody + additionalFields,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res: any) => [res.ok, res.json(), res])
      .then(([ok, dataPromise, res]) => {
        if (ok) {
          resetForm();
          setFormState(SUCCESS);
        } else {
          dataPromise.then((data: any) => {
            setFormState(ERROR);
            setErrorMessage(data.message || res.statusText);
            localStorage.setItem("loops-form-timestamp", "");
          });
        }
      })
      .catch((error) => {
        setFormState(ERROR);
        // check for cloudflare error
        if (error.message === "Failed to fetch") {
          setErrorMessage("Too many signups, please try again in a little while");
        } else if (error.message) {
          setErrorMessage(error.message);
        }
        localStorage.setItem("loops-form-timestamp", "");
      });
  };

  const isInline = formStyles.formStyle === "inline";

  switch (formState) {
    case SUCCESS:
      return (
        <Typography variant="body2" style={{ color: "var(--white)" }}>
          {formStyles.successMessage}
        </Typography>
      );
    case ERROR:
      return (
        <>
          <SignUpFormError />
          <BackButton />
        </>
      );
    default:
      return (
        <form onSubmit={handleSubmit} style={{ display: "flex" ,flexDirection: isSm ? "row" : "column", alignItems: "center", justifyContent: "center", width: "100%" }}>
          <Box display="flex" width="100%">
            <TextField
              type="text"
              name="email"
              placeholder={formStyles.placeholderText}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required={true}
            />
            <div aria-hidden="true" style={{ position: "absolute", left: "-2024px" }}>
                
            </div>
            <SignUpFormButton />
          </Box>
        </form>
      );
  }

  function SignUpFormError() {
    return (
      <Typography variant="body2" style={{ color: "var(--white)" }}>
        {errorMessage || "Oops! Something went wrong, please try again"}
      </Typography>
    );
  }

  function BackButton() {
    const [isHovered, setIsHovered] = useState(false);

    return (
      <button
        style={{
          color: "#FFF",
          font: "14px, Inter, sans-serif",
          margin: "10px auto",
          textAlign: "center",
          background: "transparent",
          border: "none",
          cursor: "pointer",
          textDecoration: isHovered ? "underline" : "none",
        }}
        onMouseOut={() => setIsHovered(false)}
        onMouseOver={() => setIsHovered(true)}
        onClick={resetForm}
      >
        &larr; Back
      </button>
    );
  }

  function SignUpFormButton({ props }: any) {
    return (
      <Button type="submit" variant="contained" className={classes.button}>
        {formState === SUBMITTING ? "..." : formStyles.buttonText}
      </Button>
    );
  }
}

function isValidEmail(email: any) {
  return /.+@.+/.test(email);
}
